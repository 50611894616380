<template>
  <v-main>
    <v-container fluid page class="mb-10">
      <v-form ref="form">
        <v-row align="center">
          <v-col cols="12 d-flex align-baseline justify-space-between">
            <h2 class="display-1 font-weight-light pt-5 pl-5">Mesas</h2>
            <v-btn
              color="primary"
              v-if="isAdmin || isEmployee"
              @click="dialog = true"
              >Asociar Pack de Mesas</v-btn
            >
          </v-col>

          <v-col cols="12" v-if="error">
            <v-alert border="left" colored-border type="error" elevation="2">
              <strong v-if="code">{{ code }}</strong>
              {{ message }}
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-card flat tile>
                  <v-card-text>
                    <v-row no-gutters class="grey--text text--darken-4">
                      <v-col
                        cols="3"
                        sm="2"
                        md="1"
                        class="subtitle-1 text-truncate"
                      ></v-col>
                      <v-col
                        cols="3"
                        sm="2"
                        md="1"
                        class="subtitle-1 text-truncate"
                        >Mesa</v-col
                      >
                      <v-col class="subtitle-1 text-truncate d-none d-md-flex"
                        >Detalles</v-col
                      >
                      <v-col class="subtitle-1 d-flex justify-end"
                        >Opciones</v-col
                      >
                    </v-row>

                    <v-row
                      no-gutters
                      align="center"
                      class="grey--text text--darken-4"
                      v-for="table in tables"
                      :key="table.uuid"
                    >
                      <v-col
                        cols="3"
                        sm="2"
                        md="1"
                        class="subtitle-1 text-truncate"
                      >
                        <v-avatar size="38" color="primary">
                          <v-icon dark>mdi-table-chair</v-icon>
                        </v-avatar>
                      </v-col>
                      <v-col
                        cols="3"
                        sm="2"
                        md="1"
                        class="subtitle-1 text-truncate"
                        >{{ table.number }}</v-col
                      >
                      <v-col
                        class="subtitle-1 text-truncate d-none d-md-flex"
                        >{{ table.uuid }}</v-col
                      >

                      <v-col class="subtitle-1 d-flex justify-end">
                        <v-switch
                          label="Habilitada"
                          :input-value="table.active"
                          false-value="false"
                          @change="toggleTable($event, table)"
                        ></v-switch>
                      </v-col>
                    </v-row>

                    <v-col cols="12" class="px-0" v-if="tables.length == 0">
                      <v-alert
                        border="left"
                        colored-border
                        type="info"
                        elevation="2"
                        >No hay ninguna mesa actualmente en el sistema.</v-alert
                      >
                    </v-col>
                  </v-card-text>
                </v-card>

                <!-- Clients table -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- Asociar pack Dialog -->
    <easy-serve-pack-dialog
      :dialog="dialog"
      @close="dialog = false"
      @saved="reload()"
    ></easy-serve-pack-dialog>
    <!-- Asociar pack Dialog -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';
import EasyServePackDialog from './components/EasyServePackDialog';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EasyServePackDialog,
  },

  data: () => ({
    tables: [],

    dialog: false,

    loading: false,
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
    }),
  },

  created() {
    // Load current restaurant data
    this.load();
  },

  methods: {
    load() {
      this.loading = true;
      this.$store
        .dispatch('tables/load', this.$route.params.restaurant)
        .then(response => {
          this.tables = response.data;
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reload() {
      this.dialog = false;
      this.load();
    },

    toggleTable(event, table) {
      // Loading state
      this.loading = true;

      // Toggle table
      const payload = {
        restaurant: this.$route.params.restaurant,
        table: table.uuid,
        number: table.number,
        active: event,
      };

      this.$store
        .dispatch('tables/update', payload)
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
