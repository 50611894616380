<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Buscar Pack</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-container no-gutter>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :counter="7"
                    label="Alias del Pack"
                    required
                    :value="alias"
                    @input="alias = $event.toUpperCase()"
                    @keydown.enter.prevent.stop="search(1)"
                  ></v-text-field>
                </v-col>
                <!-- Buttons -->
                <v-col cols="12" class="text-right">
                  <v-btn
                    color="primary"
                    @click="search(1)"
                    class="ma-2"
                    :loading="loading"
                    >Buscar</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-col cols="12" class="py-0" v-if="error">
            <v-alert border="left" colored-border type="error" elevation="2">
              <strong v-if="code">{{ code }}</strong>
              {{ message }}
            </v-alert>
          </v-col>

          <v-container>
            <v-row align="center" no-gutters>
              <v-col cols="12">
                <v-card flat tile class="order-history">
                  <v-row align="center" no-gutters>
                    <v-col cols="1"></v-col>
                    <v-col cols="5">
                      <v-card-text class="subtitle-1 px-0">Alias</v-card-text>
                    </v-col>

                    <v-col cols="3">
                      <v-card-text class="subtitle-1 text-center px-0"
                        >Mesas</v-card-text
                      >
                    </v-col>

                    <v-col cols="3">
                      <v-card-text class="subtitle-1 text-right px-0"
                        >Asignado</v-card-text
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-alert
                  :value="packs.length == 0"
                  border="left"
                  colored-border
                  type="info"
                  elevation="2"
                  class="mt-4"
                  >No hemos encontrado ningún pack con el alias
                  indicado.</v-alert
                >

                <v-card
                  v-for="(pack, index) in packs"
                  :key="'pack_' + pack.uuid"
                  flat
                  tile
                  ripple
                  class="px-0"
                  :class="packs.length - 1 == index ? '' : 'border-bottom'"
                  @click="toggle(pack)"
                >
                  <v-card-text class="px-0">
                    <v-row no-gutters align="center">
                      <!-- Checkbox for the pack -->
                      <v-col cols="1">
                        <v-icon
                          color="black"
                          class="pr-2"
                          v-if="pack.uuid != packUuid && pack.assigned == 0"
                          >mdi-checkbox-blank-circle-outline</v-icon
                        >
                        <v-icon
                          color="red darken-4"
                          class="pr-2"
                          v-else-if="pack.assigned == 1"
                          >mdi-close-circle-outline</v-icon
                        >
                        <v-icon class="pr-2" color="success" v-else
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                      </v-col>

                      <v-col
                        cols="5"
                        class="subtitle-2 text-truncate d-sm-flex"
                      >
                        <v-icon
                          class="pr-2"
                          :class="{
                            'red--text text--darken-4': pack.assigned == 1,
                          }"
                          >mdi-package-variant</v-icon
                        >
                        <span
                          class="mr-5"
                          :class="{
                            'red--text text--darken-4': pack.assigned == 1,
                          }"
                          >{{ pack.alias }}</span
                        >
                      </v-col>

                      <v-col
                        cols="3"
                        :class="{
                          'red--text text--darken-4': pack.assigned == 1,
                        }"
                        class="text-center subtitle-2"
                        >{{ pack.tables_count }}</v-col
                      >

                      <v-col cols="3" class="text-center subtitle-2">
                        <span
                          v-if="pack.assigned == 1"
                          class="red--text text--darken-4"
                          >Sí</span
                        >
                        <span v-else>No</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="$emit('close')"
            >Close</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="packUuid == null"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import errors from '../../mixins/errors';
import { mapGetters } from 'vuex';

export default {
  mixins: [errors],

  props: {
    dialog: Boolean,
  },

  data: () => ({
    alias: '',
    lastAlias: null,
    packUuid: null,

    loading: false,
  }),

  computed: {
    ...mapGetters('packs', {
      packs: 'packs',
    }),
  },

  methods: {
    save() {
      this.clearErrors();

      const payload = {
        restaurant_uuid: this.$route.params.restaurant,
        pack_uuid: this.packUuid,
      };

      this.$store
        .dispatch('packs/associate', payload)
        .then(() => {
          // Emit event ok!
          this.$emit('saved');
        })
        .catch(errors => {
          this.storeError(errors);
        });
    },

    toggle(pack) {
      if (pack.assigned == 1 || this.packUuid == pack.uuid) {
        this.packUuid = null;
      } else {
        this.packUuid = pack.uuid;
      }
    },

    search(page) {
      // Simple trick to ignore new searches.
      if (this.alias == this.lastAlias) {
        return;
      }

      this.lastAlias = this.alias;

      // Reset the selected pack
      this.packUuid = null;

      // Filter the packs
      const payload = {
        filter: { alias: this.alias },
        page,
      };

      this.loading = true;

      this.$store
        .dispatch('packs/load', payload)
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
